import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Payment } from '../payment/Payment';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 32px;
`;

const Title = styled.h2`
  font-size: 22px;
  margin-bottom: 12px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
  gap: 10px;
  display: -ms-grid;
  -ms-grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
  -ms-gap: 10px;
  padding-right: 20px;
`;

function Payments({ userInfo, platform, paymentCountry }) {
  const purchases = userInfo.filtered_purchases || [];
  const subscriptions = userInfo.filtered_subscriptions || [];

  if (subscriptions.length === 0 && purchases.length === 0) {
    return null;
  }

  return (
    <>
      {purchases.length > 0 && (
        <Wrapper>
          <Title>Purchases</Title>
          <Container>
            {purchases.map((purchase, index) => <Payment key={index} purchase={{...purchase, paymentCountry, is_purchase: true }} />)}
          </Container>
        </Wrapper>
      )}

      {subscriptions.length > 0 && (
        <Wrapper>
          <Title>Subscriptions</Title>
          <Container>
            {subscriptions.map((purchase, index) => {
              if (purchase.type == 'solid') {
                return (
                  <Payment
                    key={index}
                    purchase={{
                      expires_date: purchase.expired_at,
                      is_trial: purchase.trial == 1,
                      status: purchase.order_status,
                      original_transaction_id: purchase.original_transaction_id,
                      payment_provider: `solid (${purchase.payment_type})`,
                      purchase_date: purchase.started_at,
                      purchase_name: purchase.product_name,
                      renewal_number: purchase.renewal_number,
                      order_id: purchase.order_id,
                      user_email: userInfo.email,
                      user_id: userInfo.user_id,
                      subscription_id: purchase.subscription_id,
                      platform,
                      
                      paymentCountry,
                    }} />
                );
              }

              return (
                <Payment key={index} purchase={{...purchase, paymentCountry }} />
              );
            })}
          </Container>
        </Wrapper>
      )}
    </>
  );
}

export default Payments;
