import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { motion } from 'framer-motion';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from '../../../../lib/api';
import axios from '../../../../lib/axios';

const Wrapper = styled.div`
  background: #fff;
  padding: 24px;
  user-select: none;
  border-radius: 16px;
`;

const Actions = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: flex-end;
  button:last-child {
    margin-left: 12px;
  }

  button {
    min-width: 100px;
  }
`;

const ErrorMessage = styled(motion.p)`
  padding: 20px;
  background: var(--error);
  border-radius: 12px;
  margin-top: 10px;
  color: #fff;
  font-weight: 600;
`;

const SuccessMessage = styled(ErrorMessage)`
  background: var(--primary);
`;

const Message = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
`;

const titleMap = {
  'cancel-subscription': 'This action cancel user subscription!',
  'refund-payment': 'This action refund user payment!',
  'remove-notifications': 'This action remove user notifications!',
  'delete-user': 'This action permanently delete user!',
  'activate-lifetime': 'This action activate lifetime subscription!',
};

function UserActionModal({ modal, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleApply = async () => {
    setLoading(true);
    try {
      if (modal.actionType === 'activate-lifetime') {
        await Api.addPremium(modal.platform, 'lifetime', 1, modal.userId);
      } else {
        const data = {
          subscription_id: modal.subscription_id,
          order_id: modal.order_id,
          actionType: modal.actionType,
          userId: modal.userId,
          userEmail: modal.userEmail,
          platform: modal.platform
        };
        
        if (['refund-payment-solid','cancel-subscription-solid'].includes(modal.actionType)) {
          // [GET] api/customer/info -> userInfo.solid.subscriptions[0]
          // data.solid = { subscription_id, order_id };
          data.solid = {
            subscription_id: modal.subscription_id,
            order_id: modal.order_id,
          };
        }
        
        await Api.makeAction(data);
      }

      setSuccess(true);
      if (modal.onSuccess) {
        modal.onSuccess();
      }
      setTimeout(() => {
        handleClose();
      }, 800);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Wrapper>
      {!success && <Message>{titleMap[modal.actionType]}</Message>}
      {error && (
        <ErrorMessage
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
        >
          Something goes wrong <span>😢</span>
        </ErrorMessage>
      )}
      {success && (
        <SuccessMessage
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
        >
          Action performed successfully <span>👌</span>
        </SuccessMessage>
      )}
      {!success && (
        <Actions>
          <Button
            color="secondary"
            size="large"
            variant="text"
            disableElevation
            type="button"
            onClick={handleClose}
          >
            Dismiss
          </Button>
          <Button
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
            disableElevation
            disabled={loading}
            onClick={handleApply}
            endIcon={loading && <CircularProgress size={20} color="primary" />}
          >
            Apply
          </Button>
        </Actions>
      )}
    </Wrapper>
  );
}

export default UserActionModal;
