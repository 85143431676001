import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../store';
import { openModal } from '../../store/slices/uiSlice';
import { getAllUsersAsync } from '../../store/slices/usersSlice';
import AgentInfo from './AgentInfo';
import AgentsList from './AgentsList';

const Wrapper = styled.div`
  padding-left: 260px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 22px 16px;
  padding-right: 64px;
`;

const Title = styled.h1`
  font-size: 22px;
  text-transform: uppercase;
`;

const CreateButton = styled.button`
  color: #fff;
  font-size: 16px;
  border-radius: 12px;
  font-weight: 600;
  padding: 8px 16px;
  cursor: pointer;
  background-color: var(--primary);
  transition: all 0.3s ease;

  :hover {
    transform: scale(1.02);
  }

  :active {
    transform: scale(0.98);
  }

  span {
    margin-left: 8px;
  }
`;

function Aggents() {
  const [selectedUser, setSelectedUser] = useState(false);
  const auth = useAppSelector((state) => state.auth);
  const users = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const openCreateAgentModal = () => {
    dispatch(openModal({ type: 'create-agent' }));
  };

  useEffect(() => {
    if (auth && auth.isAdmin) {
      dispatch(getAllUsersAsync());
    }
  }, [auth, dispatch]);

  const handleSelect = (email) => () => {
    setSelectedUser(email);
  };

  const removeSelect = () => {
    setSelectedUser(false);
  };

  if (!auth) {
    return <Redirect to="/login" />;
  }

  const agent = users.find((user) => user.email === selectedUser);

  return (
    <Wrapper>
      <Heading>
        <Title>Support team 🔥</Title>
        <CreateButton onClick={openCreateAgentModal}>
          New agent <span>😎</span>
        </CreateButton>
      </Heading>
      <AgentsList
        users={users}
        selectedUser={selectedUser}
        handleSelect={handleSelect}
      />
      <AnimatePresence>
        {selectedUser && !agent.isDeleted && (
          <AgentInfo
            removeSelect={removeSelect}
            agent={users.find((user) => user.email === selectedUser)}
          />
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

export default Aggents;
