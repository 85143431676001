import styled from 'styled-components';

export const Card = styled.div`
  background: #ffccd5;
  border-radius: 16px;
  padding: 16px;
  min-width: 600px;
  max-width: 600px;
  margin-right: 16px;
`;
