import styled from 'styled-components';
import React from 'react';
import { ListItem } from '../../common/list-item/ListItem';
import { PlatformType } from '../platform/PlatformType';
import dayjs from 'dayjs';

const UserAuthContainer = styled.div`
  min-width: 400px;
  background: #ff99ac;
  border-radius: 16px;
  padding: 16px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }

  li span:first-child {
    font-weight: 600;
    font-size: 14px;
    color: var(--black);
    text-transform: uppercase;
  }

  li span:last-child {
    color: #fff;
    font-weight: 500;
  }
`;

export const UserDevice = ({ userDevice }) => {
  const { device_token, has_push_token, notify_hour, notify_minute, platform, created_at, tz_ts } = userDevice;

  return <UserAuthContainer>
    <PlatformType>{platform}</PlatformType>
    <ListItem label="DeviceID" value={device_token}/>
    <ListItem label="Has push token" value={has_push_token ? 'Yes' : 'No'}/>
    <ListItem label="Notify time" value={notify_hour + 'H ' + notify_minute + 'min '} />
    <ListItem label="Timezone offset" value={tz_ts + ' seconds'} />
    <ListItem label='Created at' value={dayjs(created_at).format('HH:mm DD MMMM YYYY')}/>
  </UserAuthContainer>
}
