import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../store';
import { getActionsAsync } from '../../store/slices/actionsSlice';
import ActionsHistory from './actions-history/ActionsHistory';
import ActiveActions from './active-actions/ActiveActions';

const Wrapper = styled.div``;
const Header = styled.header`
  display: flex;
  justify-content: center;
  padding: 32px;
`;

const Toogle = styled.div`
  display: flex;
  background: #b185db;
  border-radius: 16px;
`;

const StyledButton = styled.button`
  display: flex;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 56px;
  cursor: pointer;
  font-weight: 600;
  color: #fff;
  transition: all 0.2s ease;
  background-color: ${({ active }) => (active ? 'var(--primary)' : '#b185db')};
`;

const Content = styled.div`
  padding-top: 32px;
`;

function ActionsPage() {
  const dispatch = useAppDispatch();
  const actions = useAppSelector((state) => state.actions);
  const auth = useAppSelector((state) => state.auth);
  const [tab, setTab] = useState('active');

  useEffect(() => {
    if (auth?.isAdmin) {
      dispatch(getActionsAsync());
    }
  }, [auth, dispatch]);

  const handleTabClick = (type) => () => {
    setTab(type);
  };

  if (!auth) {
    return <Redirect to="/login" />;
  }

  return (
    <Wrapper style={{paddingLeft: 240}}>
      <Header>
        <Toogle>
          <StyledButton
            size="large"
            type="active"
            onClick={handleTabClick('active')}
            active={tab === 'active'}
          >
            In progress
          </StyledButton>
          <StyledButton
            type="history"
            onClick={handleTabClick('history')}
            active={tab === 'history'}
          >
            History
          </StyledButton>
        </Toogle>
      </Header>
      <Content>
        {tab === 'active' ? (
          <ActiveActions
            actions={actions.filter((action) => action.status === 0)}
          />
        ) : (
          <ActionsHistory
            actions={actions.filter((action) => action.status > 0)}
          />
        )}
      </Content>
    </Wrapper>
  );
}

export default ActionsPage;
