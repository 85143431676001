import { configureStore } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import authReducer from './slices/authSlice';
import usersRducer from './slices/usersSlice';
import uiReducer from './slices/uiSlice';
import actionsReducer from './slices/actionsSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersRducer,
    ui: uiReducer,
    actions: actionsReducer,
  },
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export default store;
