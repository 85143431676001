import styled from 'styled-components';

export const Meta = styled.div`
  width: 360px;
  margin-bottom: 16px;
  padding: 6px 12px;
  border-radius: 8px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.2);
`;
