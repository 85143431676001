import React, { useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 124px;
`;

const Header = styled.header`
  display: flex;
  padding: 16px;
  color: #8f9db6;
  font-weight: 600;

  .action {
    width: 12%;
  }

  .customer {
    width: 25%;
  }

  .customer {
    width: 25%;
  }

  .date {
    width: 15%;
  }

  .agent {
    width: 20%;
  }

  .status {
    padding-left: 14px;
    width: 10%;
  }
`;

const List = styled.ul`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 32px;
`;

const Item = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 2px 2px #eaeaea;
  padding: 12px 0;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const ActionType = styled.p`
  padding-left: 16px;
  color: var(--primary);
  width: 12%;
  text-transform: capitalize;
`;

const Agent = styled.div`
  display: flex;
  width: 20%;
  align-items: center;

  p {
    padding-right: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    width: 38px;
    height: 38px;
    margin-right: 8px;
  }

  .admin {
    background-color: #8f9db6;
    padding: 4px 8px;
    color: #fff;
    border-radius: 8px;
  }
`;

const Date = styled.p`
  width: 15%;
  p:last-child {
    color: #8f9db6;
  }
`;

const CustomerEmail = styled.p`
  width: 25%;
  padding-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Status = styled.p`
  background: ${({ status }) =>
    status === 1 ? 'rgb(4, 191, 148)' : 'var(--error)'};
  color: #fff;
  padding: 4px 8px;
  border-radius: 16px;
  width: 10%;
  text-align: center;
`;

const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    justify-content: center;
  }
`;

const ITEM_PER_PAGE = 8;

const paginateItems = (page, items) => {
  if (items.length < ITEM_PER_PAGE) {
    return items;
  }
  const startIndex = (page - 1) * ITEM_PER_PAGE;
  const endIndex = page * ITEM_PER_PAGE;
  return items.slice(startIndex, endIndex);
};

function ActionsHistory({ actions }) {
  const [currentPage, setCurrentPage] = useState(1);
  if (actions.length === 0) {
    return null;
  }

  const sortedActionsByUpdate = actions.sort((a, b) => {
    return a.updatedAt > b.updatedAt ? -1 : a.updatedAt < b.updatedAt ? 1 : 0;
  });

  const pages = Math.ceil(actions.length / ITEM_PER_PAGE);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const paginatedItems = paginateItems(currentPage, sortedActionsByUpdate);
  
  return (
    <Wrapper>
      <Header>
        <p className="action">Action</p>
        <p className="customer">Customer</p>
        <p className="agent">Agent</p>
        <p className="date">Created</p>
        <p className="date">Processed</p>
        <p className="status">Status</p>
      </Header>
      <List>
        {paginatedItems.map((action) => {
          return (
            <Item key={action._id}>
              <ActionType>{action.actionType.replace('-', ' ')}</ActionType>
              <CustomerEmail>{action.customerEmail}</CustomerEmail>
              <Agent>
                {action.executor.isAdmin ? (
                  <p className="admin">Admin</p>
                ) : (
                  <p>{action.executor.email}</p>
                )}
              </Agent>
              <Date>
                <p>{dayjs(action.createdAt).format('DD MMMM YYYY')}</p>
                <p>{dayjs(action.createdAt).format('HH:mm')}</p>
              </Date>
              {action.updatedAt && (
                <Date>
                  <p>{dayjs(action.updatedAt).format('DD MMMM YYYY')}</p>
                  <p>{dayjs(action.updatedAt).format('HH:mm')}</p>
                </Date>
              )}
              <Status status={action.status}>
                {action.status === 1 ? 'Approved' : 'Rejected'}
              </Status>
            </Item>
          );
        })}
      </List>
      <StyledPagination
        color="primary"
        count={pages}
        page={currentPage}
        onChange={handlePageChange}
      />
    </Wrapper>
  );
}

export default ActionsHistory;
