import React from 'react';
import styled from 'styled-components';
import pdfIcon from '../../../assets/pdf.svg';

export const Link = styled.a`
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease;

  :hover {
    transform: scale(1.1);
  }
`;

export const PDFLink = ({href, show}) => {
  if (!show) return null;

  return <Link target="_blank" rel="noreferrer" href={href}>
    <img alt="pdf" wisth="48" height="48" src={pdfIcon} />
  </Link>
}
