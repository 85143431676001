import React from 'react';
import styled from 'styled-components';
import { UserDevice } from '../device/UserDevice';

const Wrapper = styled.div``;

const Title = styled.h2`
  font-size: 22px;
  margin-bottom: 12px;
`;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-bottom: 24px;
`;


function UserDevices({ data }) {
  return (
    <Wrapper>
      <Title>User devices</Title>
      <Container>
        {
          data.map((item, index) => <UserDevice key={index} userDevice={item} />)
        }
      </Container>
    </Wrapper>
  );
}

export default UserDevices;
