import React from 'react';
import styled from 'styled-components';
import AnimatedMagnifier from '../../components/common/lottie/AnimatedMagnifier';
import { useAppSelector } from '../../store';
import Search from './Search';

const Wrapper = styled.div`
  width: 75vw;
  height: 420px;
  border-radius: 40px;
  margin-top: 74px;
  background-color: #d2b7e5;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: all 0.4s;
  transform: ${({ loading }) =>
    loading ? 'translateY(-20px)' : 'translateY(0)'};
  opacity: ${({ loading }) => (loading ? '0' : '1')};

  @media (max-height: 650px) {
    height: 350px;
  }
`;

const Message = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 38px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
`;

const AnimationWrapper = styled.div`
  position: absolute;
  right: 44px;
  top: -24px;

  @media (max-width: 1400px) {
    right: -20px;
  }

  @media (max-height: 680px) {
    top: -64px;
    right: -20px;
  }
`;

function SearchWidget({
  loading,
  notFound,
  fetchUser,
  setNotFound,
  setLoading,
}) {
  const auth = useAppSelector((state) => state.auth);
  return (
    <Wrapper loading={loading}>
      <Message>
        <Title>Hi, {auth.firstName} 😉</Title>
        <Subtitle>Find user by email or id</Subtitle>
      </Message>
      <Search
        loading={loading}
        notFound={notFound}
        fetchUser={fetchUser}
        setNotFound={setNotFound}
        setLoading={setLoading}
      />
      <AnimationWrapper>
        <AnimatedMagnifier />
      </AnimationWrapper>
    </Wrapper>
  );
}

export default SearchWidget;
