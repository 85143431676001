import React from 'react';
import styled from 'styled-components';
import Platforms from './Platforms';
import Payments from './Payments';
import UserDevices from './UserDevices';
import ExpertHelps from './ExpertHelps';
import dayjs from 'dayjs';
import UserAuths from './UserAuths';

const Wrapper = styled.div`
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #585858;
`;

const Info = styled.ul`
  margin-bottom: 12px;
  width: 100%;
`;
const Items = styled.li`
  margin-bottom: 12px;
  p:first-of-type {
    font-weight: 700;
    font-size: 22px;
    color: var(--black);
  }

  p:last-of-type {
    font-weight: 600;
    font-size: 16px;
    color: var(--primary);
  }
`;

const Notifications = styled.ul`
  width: 100%;
  margin-bottom: 24px;
  display: flex;

  li {
    background: #d2b7e5;
    border-radius: 12px;
    padding: 12px;
    min-width: 200px;
    margin-right: 12px;
    p:first-child {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 2px;
      color: var(--black);
      text-transform: uppercase;
    }
    p:last-child {
      font-weight: 500;
      color: #fff;
    }
  }
`;

const Title = styled.h2`
  font-size: 22px;
  margin-bottom: 12px;
`;

function GeneralInformation({ data }) {
  const { userInfo, amplitudeData, platform } = data;
  const { promotion_email, send_email, push_notification: web_push_notification } = userInfo.user_auth_data;
  const { push, care } = userInfo?.notification_config || {};

  const now = Date.now() / 1000;
  const subscriptionDuration = (userInfo.user_auth_data.is_subscribed && userInfo.user_auth_data.sub_end_timestamp || 0) - now;
  const isSubscriptionLifetime = subscriptionDuration > 315360000; // 10 Years

  const showPlatformInformation = amplitudeData && amplitudeData.length > 0;
  const hasPremiumAccess = userInfo.user_auth_data.is_subscribed && userInfo.user_auth_data.sub_end_timestamp > ( Date.now() / 1000);

  return (
    <Wrapper>
      <Info>
        <Items>
          <p>Email</p>
          <p>{userInfo.email}</p>
        </Items>
        <Items>
          <p>User id</p>
          <p>{userInfo.user_id}</p>
        </Items>
        { userInfo?.user_auth_data?.user_name &&
          <Items>
            <p>User name</p>
            <p>{userInfo?.user_auth_data?.user_name}</p>
          </Items>
        }
        { userInfo?.user_auth_data?.created_at &&
          <Items>
            <p>Created at</p>
            <p>{dayjs(userInfo?.user_auth_data?.created_at).format('HH:mm DD MMMM YYYY')}</p>
          </Items>
        }
        <Items>
          <p>Platform</p>
          <p>{platform === 'plantin' ? 'PlantIn' : 'Carl'}</p>
        </Items>
        <Items>
          <p>Has premium access</p>
          <p>{hasPremiumAccess  ?  'Yes' : `No`}</p>
        </Items>
        <Items>
          <p>Has lifetime access</p>
          <p>{isSubscriptionLifetime  ?  'Yes' : `No`}{isSubscriptionLifetime && (userInfo.is_lifetime_available ? ' (Provided by support)' : ' (Purchased)')}</p>
        </Items>
      </Info>
      {
        platform === 'plantin' &&
        <div>
          <Title>Notifications (mobile platform)</Title>
          <Notifications>
            <li>
              <p>Enabled</p>
              <p>{push ? 'Enabled' : 'Disabled'}</p>
            </li>
            <li>
              <p>Care</p>
              <p>{care ? 'Enabled' : 'Disabled'}</p>
            </li>
            <li>
              <p>Weather</p>
              <p>{userInfo?.user_auth_data?.weather_notify ? 'Enabled' : 'Disabled'}</p>
            </li>
          </Notifications>
        </div>
      }
      {
        platform === 'carl' &&
        <div>
          <Title>Notifications</Title>
          <Notifications>
            <li>
              <p>Emails</p>
              <p>{send_email ? 'Enabled' : 'Disabled'}</p>
            </li>
            <li>
              <p>Promotion emails</p>
              <p>{promotion_email ? 'Enabled' : 'Disabled'}</p>
            </li>
            <li>
              <p>Push notifications</p>
              <p>{web_push_notification ? 'Enabled' : 'Disabled'}</p>
            </li>
          </Notifications>
        </div>
      }


      {userInfo.auths && <UserAuths data={userInfo.auths} /> }
      {userInfo.devices &&  <UserDevices data={userInfo.devices} /> }

      <Payments
        userInfo={userInfo}
        platform={platform}
        paymentCountry={
          amplitudeData &&
          amplitudeData.length > 0 &&
          amplitudeData[0].amp_props &&
          `${amplitudeData[0].amp_props.country} (${amplitudeData[0].amp_props.city})`
        }
      />

      {showPlatformInformation && (
        <Platforms data={amplitudeData} />
      )}
      <ExpertHelps email={userInfo.email} />
    </Wrapper>
  );
}

export default GeneralInformation;
