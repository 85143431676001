import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Api from '../../lib/api';
import AnimatedEmpty from '../common/lottie/AnimatedEmpty';

const Content = styled.div`
  flex-grow: 1;
  padding: 0px 32px;
`;

const AnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const Subtitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #6d778a;
`;

const Table = styled.div``;

const Header = styled.header`
  display: flex;
  padding: 16px;
  padding-top: 0px;
  color: #8f9db6;
  font-weight: 600;

  > * {
    width: 20%;
  }
`;

const List = styled.ul`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 2px 2px #eaeaea;
  padding: 8px 16px;
  border-radius: 8px;
  margin-bottom: 20px;

  p {
    width: 20%;
  }
`;

const ActionType = styled.p`
  color: var(--primary);
  text-transform: capitalize;
`;

const Date = styled.p`
  span {
    display: block;
  }
  span:last-child {
    color: #8f9db6;
  }
`;

const CustomerEmail = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Status = styled.div`
  background: ${({ status }) =>
    status === 1 ? 'rgb(4, 191, 148)' : 'var(--error)'};
  color: #fff;
  padding: 4px 12px;
  border-radius: 16px;
  text-align: center;
  margin-left: auto;
`;

function AgentActionsHistory({ agent }) {
  const [actions, setActions] = useState(false);

  useEffect(() => {
    const fetchAgentActions = async () => {
      const response = await Api.getActionsByAgent(agent._id);
      setActions(response.data);
    };
    fetchAgentActions();
  }, [agent._id]);

  if (!actions) {
    return null;
  }

  return (
    <Content>
      {actions.length > 0 ? (
        <Table>
          <Header>
            <p className="action">Action</p>
            <p className="customer">Customer</p>
            <p className="date">Created</p>
            <p className="date">Processed</p>
          </Header>
          <List>
            {actions.map((action) => {
              return (
                <Item key={action._id}>
                  <ActionType>{action.actionType.replace('-', ' ')}</ActionType>
                  <CustomerEmail>{action.customerEmail}</CustomerEmail>
                  <Date>
                    <span>
                      {dayjs(action.createdAt).format('DD MMMM YYYY')}
                    </span>
                    <span>{dayjs(action.createdAt).format('HH:mm')}</span>
                  </Date>
                  {action.updatedAt && (
                    <Date>
                      <span>
                        {dayjs(action.updatedAt).format('DD MMMM YYYY')}
                      </span>
                      <span>{dayjs(action.updatedAt).format('HH:mm')}</span>
                    </Date>
                  )}
                  <Status status={action.status}>
                    {action.status === 1 ? 'Approved' : 'Rejected'}
                  </Status>
                </Item>
              );
            })}
          </List>
        </Table>
      ) : (
        <AnimationWrapper>
          <Title>Here you can view a list of actions from this agent</Title>
          <Subtitle>Currently history is empty</Subtitle>
          <AnimatedEmpty />
        </AnimationWrapper>
      )}
    </Content>
  );
}

export default AgentActionsHistory;
