import styled from 'styled-components';
import React from 'react';
import { ListItem } from '../../common/list-item/ListItem';
import dayjs from 'dayjs';

const UserAuthContainer = styled.div`
  min-width: 400px;
  background: #ff99ac;
  border-radius: 16px;
  padding: 16px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }

  li span:first-child {
    font-weight: 600;
    font-size: 14px;
    color: var(--black);
    text-transform: uppercase;
  }

  li span:last-child {
    color: #fff;
    font-weight: 500;
  }
`;

export const UserAuth = ({ userAuth }) => {
  const { origin, email, is_apple, is_facebook, is_google, send_email, push_notification,
    promotion_email, created_at
  } = userAuth;


  return <UserAuthContainer>
    <ListItem label="Email" value={email}/>
    <ListItem label="Origin" value={origin}/>
    <ListItem label='Google auth' value='possible' show={is_google}/>
    <ListItem label='Facebook auth' value='possible' show={is_facebook}/>
    <ListItem label='Apple auth' value='possible' show={is_apple}/>
    <ListItem label='Email notification' value='Enabled' show={send_email}/>
    <ListItem label='Promotional email notification' value='Enabled' show={promotion_email}/>
    <ListItem label='Push notification (web auth)' value={push_notification ? 'Enabled' : 'Disabled'} show={origin === 'web'}/>
    <ListItem label='Created at' value={dayjs(created_at).format('HH:mm DD MMMM YYYY')}/>
  </UserAuthContainer>
}
