import styled from 'styled-components';
import React from 'react';

export const MessageContainer = styled.p`
  margin-bottom: 16px;
`;

export const Message = ({message}) => {
  return <MessageContainer>
    <strong>Message:&nbsp;</strong>
    {message}
  </MessageContainer>
}