import React, { useRef, useEffect } from 'react';
import Lottie from 'lottie-react';
import notFound from '../../../assets/lottie/not-found.json';

const lottieIconStyles = {
  width: '200px',
};

const AnimatedNotFound = () => {
  return (
    <Lottie loop={false} style={lottieIconStyles} animationData={notFound} />
  );
};

export default AnimatedNotFound;
