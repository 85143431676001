import React from 'react';

function PasswordIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="var(--primary)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconly/Bold/Password">
        <g id="Password">
          <path
            id="Password_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.0108 14.6012C17.4248 14.6012 17.7608 14.2652 17.7608 13.8512V11.9992C17.7608 11.5852 17.4248 11.2492 17.0108 11.2492H11.3188C10.9948 10.1822 10.0128 9.39821 8.84076 9.39821C7.40676 9.39821 6.23976 10.5652 6.23976 11.9992C6.23976 13.4342 7.40676 14.6012 8.84076 14.6012C10.0128 14.6012 10.9948 13.8172 11.3188 12.7492H13.4308V13.8512C13.4308 14.2652 13.7668 14.6012 14.1808 14.6012C14.5948 14.6012 14.9308 14.2652 14.9308 13.8512V12.7492H16.2608V13.8512C16.2608 14.2652 16.5968 14.6012 17.0108 14.6012ZM7.66576 1.99921H16.3348C19.7228 1.99921 21.9998 4.37721 21.9998 7.91621V16.0832C21.9998 19.6222 19.7228 21.9992 16.3338 21.9992H7.66576C4.27676 21.9992 1.99976 19.6222 1.99976 16.0832V7.91621C1.99976 4.37721 4.27676 1.99921 7.66576 1.99921ZM7.73886 12.0001C7.73886 11.3921 8.23386 10.8981 8.84086 10.8981C9.44786 10.8981 9.94286 11.3921 9.94286 12.0001C9.94286 12.6071 9.44786 13.1011 8.84086 13.1011C8.23386 13.1011 7.73886 12.6071 7.73886 12.0001Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default PasswordIcon;
