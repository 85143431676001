import React from 'react';
import Lottie from 'lottie-react';
import processing from '../../../assets/lottie/processing.json';

const lottieIconStyles = {
  width: '700px',
  height: '400px',
};

const AnimatedAvocado = () => {
  return <Lottie loop style={lottieIconStyles} animationData={processing} />;
};

export default AnimatedAvocado;
