import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Api from '../../../lib/api';
import pdfIcon from '../../../assets/pdf.svg';
import { ExpertHelp } from '../expert-help/ExpertHelp';

const Wrapper = styled.div``;

const Title = styled.h2`
  font-size: 22px;
  margin-bottom: 12px;
`;

function ExpertHelps({ email }) {
  const [expertHelps, setExpertHelps] = useState([]);

  useEffect(() => {
    const getExpertHelp = async () => {
      try {
        const response = await Api.getExpertHelp(email);
        setExpertHelps(response.data);
      } catch (e) {
        console.log(e);
      }
    };
    if (email) {
      getExpertHelp();
    }
  }, [email]);

  if (expertHelps.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Title>Expert help</Title>
      {expertHelps.map((expertHelp) => <ExpertHelp key={expertHelp.requestId} expertHelp={expertHelp} /> )}
    </Wrapper>
  );
}

export default ExpertHelps;
