import React from 'react';

function LogoIcon() {
  return (
    <svg
      width="20"
      height="27"
      viewBox="0 0 20 27"
      fill="var(--primary)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.0001 0C12.5568 1.45408 14.2687 3.64007 15.1358 6.07429C12.9293 7.12896 11.1219 8.87883 10.0001 11.0387C8.87826 8.87883 7.07082 7.12896 4.86435 6.07429C5.73146 3.64007 7.44336 1.45408 10.0001 0Z"></path>
      <path d="M0 6.22051C5.52285 6.22051 10 10.6765 10 16.1733C10 10.6765 14.4772 6.22051 20 6.22051V16.1733C20 21.6701 15.5228 26.1261 10 26.1261C4.47715 26.1261 0 21.6701 0 16.1733V6.22051Z"></path>
    </svg>
  );
}

export default LogoIcon;
