import React, { useRef, useEffect } from 'react';
import Lottie from 'lottie-react';
import search from '../../../assets/lottie/search.json';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const lottieIconStyles = {
  width: '500px',
};

const AnimatedAvocado = () => {
  const lottieRef = useRef();
  useEffect(() => {
    lottieRef.current.setSpeed(0.3);
  }, []);
  return (
    <Wrapper>
      <Lottie
        lottieRef={lottieRef}
        style={lottieIconStyles}
        animationData={search}
      />
    </Wrapper>
  );
};

export default AnimatedAvocado;
