import React from 'react';

function ShowIcon({ fill }) {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0021 0C14.1381 0.00198782 17.853 2.88632 19.94 7.71375C20.02 7.89663 20.02 8.10337 19.94 8.28724C19.692 8.86073 19.416 9.41831 19.118 9.94111C18.919 10.293 18.475 10.4152 18.121 10.2184C17.769 10.0196 17.645 9.57734 17.843 9.22748C18.065 8.83687 18.275 8.4244 18.468 8C16.601 3.89614 13.4531 1.45608 10.0001 1.45509C6.54617 1.45608 3.39821 3.89614 1.53123 8C3.39821 12.1039 6.54617 14.5449 10.0001 14.5449C12.0081 14.5449 13.9451 13.714 15.6021 12.1406C15.8951 11.8653 16.357 11.8753 16.636 12.1655C16.916 12.4547 16.904 12.9149 16.612 13.1922C14.6791 15.028 12.3941 15.999 10.0021 16H9.99813H9.99712C5.86218 15.998 2.14722 13.1147 0.0592493 8.28724C-0.0197498 8.10436 -0.0197498 7.89663 0.0592493 7.71375C2.14722 2.88632 5.86218 0.00198782 9.99712 0H10.0001H10.0021ZM8.74864 10.0789C9.12464 10.3035 9.55763 10.4218 9.99963 10.4218C11.3426 10.4218 12.4356 9.33542 12.4356 7.9996C12.4356 6.66577 11.3426 5.57943 9.99963 5.57943C8.65664 5.57943 7.56366 6.66577 7.56366 7.9996C7.56366 8.40214 7.23566 8.72715 6.83166 8.72715C6.42767 8.72715 6.10067 8.40214 6.10067 7.9996C6.10067 5.86269 7.84965 4.12434 9.99963 4.12434C12.1496 4.12434 13.8986 5.86269 13.8986 7.9996C13.8986 10.1375 12.1496 11.8759 9.99963 11.8759C9.29163 11.8759 8.59864 11.685 7.99565 11.3242C7.64865 11.1185 7.53666 10.6712 7.74565 10.3264C7.95365 9.98345 8.40364 9.86915 8.74864 10.0789Z"
      />
    </svg>
  );
}

export default ShowIcon;
