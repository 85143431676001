import axios from './axios';

export default class Api {
  static async fetchUserInfo(value, platform) {
    const query = value.includes('@') ? `email=${value}` : `user_id=${value}`;
    const response = await axios({
      method: 'get',
      url: `/customer/info?${query}&platform=${platform}`,
    });

    return response;
  }

  static async addPremium(platform, period, periodNum, userId, email) {
    const response = await axios({
      method: 'post',
      url: `/customer/premium`,
      params: {
        userId: userId,
        email: email,
        platform: platform,
        period: period,
        periodNum: periodNum,
      }
    });

    return response;
  }

  static async getActions() {
    const response = await axios({
      method: 'get',
      url: `/action`,
    });

    return response;
  }

  static async getActionsByAgent(email) {
    const response = await axios({
      method: 'get',
      url: `/action/history?executor=${email}`,
    });

    return response;
  }

  static async makeAction(data, platform) {
    const response = await axios({
      method: 'post',
      url: `/action`,
      data,
      platform
    });

    return response;
  }

  static async approveMultipleActions(actions) {
    const response = await axios({
      method: 'post',
      url: `/action/approve`,
      data: { actions },
    });

    return response;
  }

  static async rejectAction(actionId) {
    const response = await axios({
      method: 'put',
      url: `/action/reject`,
      data: { actionId },
    });

    return response;
  }

  static async getExpertHelp(email) {
    const response = await axios({
      method: 'get',
      url: `/customer/expert-help?email=${email}`,
    });

    return response;
  }

  static async resetPassword({ userId, newPassword }) {
    const response = await axios({
      method: 'put',
      url: `/user/password/reset/${userId}`,
      data: { newPassword },
    });

    return response;
  }
}
