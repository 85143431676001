import { motion } from 'framer-motion';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { closeModal } from '../../../store/slices/uiSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import CreateAgentModal from './create-aggent/CreateAggentModal';
import UserActionModal from './user-action/UserActionModal';
import DeleteAgentModal from './delete-agent/DeleteAgentModal';
import NewPasswordModal from './new-password/NewPasswordModal';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* backdrop-filter: blur(12px); */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 99;
  padding: 2rem 1rem;
  perspective: 1000px;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Container = styled(motion.div)`
  position: relative;
  margin-bottom: 2rem;
  max-height: calc(100% - 64px);
  max-width: 940px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const ModalManager = () => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector((state) => state.ui.modal);

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        handleClose();
      }
    };

    document.addEventListener('keydown', handleEscape, false);

    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [modal, handleClose]);

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  const renderModalByType = () => {
    switch (modal.type) {
      case 'create-agent':
        return <CreateAgentModal handleClose={handleClose} modal={modal} />;
      case 'user-action':
        return <UserActionModal handleClose={handleClose} modal={modal} />;
      case 'delete-agent':
        return <DeleteAgentModal handleClose={handleClose} modal={modal} />;
      case 'new-password':
        return <NewPasswordModal handleClose={handleClose} modal={modal} />;
      default:
        throw Error('no such modal type in Modal Manager');
    }
  };

  if (!modal.type) return null;

  return (
    <Backdrop
      onTouchStartCapture={handlePropagation}
      onTouchMoveCapture={handlePropagation}
    >
      <Container
        initial={{ y: -15 }}
        animate={{ y: 0 }}
        exit={{ y: 20 }}
        onClick={handlePropagation}
        onTouchStart={handlePropagation}
      >
        <>{renderModalByType()}</>
      </Container>
    </Backdrop>
  );
};

export default ModalManager;
