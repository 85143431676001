import React from 'react';
import styled from 'styled-components';
import { ListItem } from '../../common/list-item/ListItem';
import { PlatformType } from './PlatformType';

const PlatformContainer = styled.div`
  min-width: 400px;
  background: #ff99ac;
  border-radius: 16px;
  padding: 16px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }

  li span:first-child {
    font-weight: 600;
    font-size: 14px;
    color: var(--black);
    text-transform: uppercase;
  }

  li span:last-child {
    color: #fff;
    font-weight: 500;
  }
`;


export const Platform = ({ platform, deviceInformation }) => {
  const {
    device,
    device_type,
    country,
    city,
    os: OperatingSystem,
    version,
    first_used,
    last_used
  } = deviceInformation;

  const location = `${country}, ${city}`;

  const os = OperatingSystem;

  const isIOS = platform === 'ios';

  return <PlatformContainer>
    <PlatformType>{platform}</PlatformType>
    <ListItem label='Device' value={device} show={!isIOS}/>
    <ListItem label='Device type' value={device_type} show={isIOS}/>
    <ListItem label='Location' value={location} />
    <ListItem label='OS' value={os} />
    <ListItem label='App version' value={version} show={!!version}/>
    <ListItem label='First usage' value={first_used} />
    <ListItem label='Last usage' value={last_used} />
  </PlatformContainer>;
};