import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import GeneralInformation from './GeneralInformation';
import UserActions from './UserActions';
import ArrowBackIcon from '../../common/icons/ArrowBackIcon';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import Api from '../../../lib/api';
import AnimatedSearching from '../../common/lottie/AnimatedSearching';
import { useAppSelector } from '../../../store';

const Wrapper = styled.div`
  width: 100%;
  padding-top: 16px;
  padding-left: 300px;
  min-height: 100vh;
  transition: all 0.8s;
  position: relative; ;
`;
const Header = styled(motion.header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 34px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  border-bottom: 1px solid #b185db1f;
`;

const BackButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;

  span {
    margin-left: 8px;
    font-size: 14px;
    text-transform: uppercase;
  }

  svg {
    transition: 0.3s ease;
  }

  :hover {
    svg {
      transform: translateX(-5px);
    }
  }
`;

const LoadingWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: var(--primary);
  }
`;

const Content = styled(motion.div)`
  background: #fff;
`;

function UserInfo() {
  let { platform, userId } = useParams();
  let [ loading, setLoading ] = useState(true);
  let [ data, setData ] = useState(null);
  const auth = useAppSelector((state) => state.auth);

  const history = useHistory();

  useEffect(() => {
    if (!auth._id) {
      return;
    }
    (async () => {
      try {
        const response = await Api.fetchUserInfo(userId, platform);
        setData({ ...response.data, platform});
        setLoading(false);
      } catch (e) {
        console.log(e);
        // Redirect to root
        history.push('/');
      }
    })();
  }, [auth, setData, history, userId, platform]);

  const renderUserInfo = () => {
    if (loading) {
      return (
        <LoadingWrapper>
          <AnimatedSearching />
          <span>Loading...</span>
        </LoadingWrapper>
      );
    } else if (data) {
      return (<Wrapper>
        <Header
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          <BackButton onClick={
            () => {
              history.push('/');
            }
          }>
            <ArrowBackIcon />
            <span>back to search</span>
          </BackButton>
          <UserActions data={data} />
        </Header>
        <Content
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <GeneralInformation data={data} />
        </Content>
      </Wrapper>);
    } else {
      return (
        <Redirect to={'/'} />
      );
    }
  }

  return (
    renderUserInfo()
  );
}

export default UserInfo;
