import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: { type: '', props: {} },
};

const OPEN_MODAL = (state, action) => {
  state.modal = action.payload;
};

const CLOSE_MODAL = (state) => {
  state.modal = initialState.modal;
};

const authSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: { openModal: OPEN_MODAL, closeModal: CLOSE_MODAL },
});

export const { openModal, closeModal } = authSlice.actions;

export default authSlice.reducer;
