import React, { useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from '../../../../store';
import { createUserAsync } from '../../../../store/slices/usersSlice';
import Form from './Form';
import UserIdentity from './UserIdentity';

const Wrapper = styled.div`
  .height-util {
    height: 20px;
  }
`;

const Modal = styled.div`
  background: #fff;
  user-select: none;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 10%) 0px 12px 56px, rgb(0 0 0 / 6%) 0px 6px 30px,
    rgb(0 0 0 / 5%) 0px 3px 12px;
`;

const Content = styled.div`
  display: flex;
  align-items: stretch;
`;

function CreateAggentModal({ handleClose }) {
  const dispatch = useAppDispatch();
  const [gender, setGender] = useState('female');
  const [loading, setLoading] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onFormSubmit = (values) => {
    const createUser = async () => {
      setLoading(true);
      const response = await dispatch(createUserAsync({ ...values, gender }));
      if (response.meta.requestStatus === 'rejected') {
        if (response.payload.status === 400) {
          setError('Such user already exist');
        } else {
          setError('Something goes wrong');
        }
      } else {
        setSuccess(true);
        setTimeout(() => {
          handleClose();
        }, 1500);
      }
      setLoading(false);
    };

    createUser();
  };

  return (
    <Wrapper>
      <Modal>
        <Content>
          <UserIdentity onSelect={setGender} />
          <Form
            error={error}
            success={success}
            loading={loading}
            onSubmit={onFormSubmit}
            handleClose={handleClose}
          />
        </Content>
      </Modal>
      <div className="height-util" />
    </Wrapper>
  );
}

export default CreateAggentModal;
