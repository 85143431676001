import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { motion } from 'framer-motion';
import { useAppDispatch } from '../../../store';
import { openModal } from '../../../store/slices/uiSlice';
import { hasTransactions, isLastTransactionCancelled, getActiveSubscription } from '../../../utils/transaction.utils';

const Wrapper = styled(motion.div)`
  display: flex;
  background-color: #fff;
  border-radius: 12px;
`;

const StyledButton = styled(Button)`
  border-radius: 6px;
  margin-right: 12px;
  border-radius: 8px;
  font-weight: 500;
  text-transform: capitalize;
`;

function UserActions({ data }) {
  const dispatch = useAppDispatch();

  const { userInfo } = data;
  const [success, setSuccess] = useState({});

  const now = Date.now() / 1000;
  const subscriptionDuration = (userInfo.user_auth_data.is_subscribed && userInfo.user_auth_data.sub_end_timestamp || 0) - now;
  const isSubscriptionLifetime = subscriptionDuration > 315360000; // 10 Years
  const subscriptions = userInfo.solid && userInfo.solid.subscriptions || [];
  const {subscription_id, order_id} = getActiveSubscription(subscriptions);

  const handleModal = (actionType) => () => {
    dispatch(
      openModal({
        type: 'user-action',
        actionType,
        userEmail: data.userInfo.email,
        userId: data.userInfo.user_id,
        subscription_id: subscription_id,
        order_id: order_id,
        platform: data.platform,
        onSuccess: () => {
          setSuccess((prev) => ({ ...prev, [actionType]: true }));
          if (actionType === 'activate-lifetime') {
            window.location.reload();
          }
        },
        onError: () => {
          console.log('erorr');
        },
      })
    );
  };

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.3 }}
    >
      {hasTransactions(userInfo.paypal) && !isLastTransactionCancelled(userInfo.paypal) && !success['cancel-subscription'] && (
        <StyledButton
          variant="outlined"
          color="primary"
          disableElevation
          disableElavation
          onClick={handleModal('cancel-subscription')}
        >
          Cancel Subscription
        </StyledButton>
      )}
      {subscription_id && !success['cancel-subscription-solid'] && (
        <StyledButton
          variant="outlined"
          color="primary"
          disableElevation
          disableElavation
          onClick={handleModal('cancel-subscription-solid')}
        >
          Cancel Subscription
        </StyledButton>
      )}
      {!isSubscriptionLifetime && !success['activate-lifetime'] && (
        <StyledButton
          variant="outlined"
          color="primary"
          disableElevation
          disableElavation
          onClick={handleModal('activate-lifetime')}
        >Activate lifetime
        </StyledButton>
      )}
      {userInfo.paypal.length > 0 && !success['refund-payment'] && (
        <StyledButton
          variant="outlined"
          color="primary"
          disableElevation
          disableElavation
          onClick={handleModal('refund-payment')}
        >
          Refund payment
        </StyledButton>
      )}
      {!success['remove-notifications'] && (
        <StyledButton
          variant="outlined"
          color="primary"
          size="large"
          disableElevation
          disableElavation
          onClick={handleModal('remove-notifications')}
        >
          Remove notifications
        </StyledButton>
      )}
      {!success['delete-user'] && (
        <StyledButton
          variant="outlined"
          color="primary"
          disableElevation
          disableElavation
          onClick={handleModal('delete-user')}
        >
          Delete user
        </StyledButton>
      )}
    </Wrapper>
  );
}

export default UserActions;
