import { createTheme } from '@material-ui/core/styles';

const commonTheme = {
  palette: {
    primary: {
      main: '#6247aa',
      contrastText: '#fff',
    },
    secondary: {
      main: '#3b4150',
      dark: '#383d4b',
      light: '#464c5d',
      contrastText: '#cecece',
    },
    error: {
      main: '#EC6D62',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '12px',
        lineHeight: 1.6,
        fontSize: '1rem',
        fontWeight: 500,
      },
    },
    MuiSkeleton: {
      root: {
        backgroundColor: '#E0E5EE',
      },
    },
    MuiTooltip: { tooltip: { backgroundColor: '#6247aa' } },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '0px',
      },
    },
    MuiTab: {
      labelIcon: {
        minWidth: '100px',
      },
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
  },
};

export default createTheme(commonTheme);
