import React from 'react';
import { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from '../../../../lib/api';
import HidePasswordIcon from '../../../common/icons/HidePasswordIcon';
import ShowPasswordIcon from '../../../common/icons/ShowPasswordIcon';
import LockIcon from '../../../common/icons/LockIcon';

const Content = styled.div`
  padding: 28px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 12px 56px, rgb(0 0 0 / 6%) 0px 6px 30px,
    rgb(0 0 0 / 5%) 0px 3px 12px;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 18px;
  color: var(--black);
  margin-bottom: 24px;
  span {
    color: var(--primary);
  }
`;

const Form = styled.form`
  min-width: 350px;
  > div {
    margin-bottom: 18px;
  }

  @media screen and (max-width: 600px) {
    min-width: 300px;
  }
`;

const Actions = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  width: 100px;

  :first-of-type {
    margin-right: 16px;
  }
`;

const Success = styled(motion.p)`
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #1f877f;
  border-radius: 8px;

  span {
    font-size: 24px;
    margin-left: 8px;
  }
`;

const Field = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 16px;
  margin-bottom: 20px;
  display: flex;
  padding: 8px 18px;
  align-items: center;
  transition: border-color 0.3s ease;
  background: #e9e9e9;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
const Input = styled.input`
  width: 100%;
  display: inline-block;
  height: 100%;

  border-radius: inherit;
  outline: none;
  font-size: 18px;
  line-height: 21px;
  color: var(--black);
  margin-left: 8px;
  background-color: transparent;
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: var(--black);
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  ::placeholder {
    color: gray;
  }
`;

const IconButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function NewPasswordModal({ modal, handleClose }) {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await Api.resetPassword({
        userId: modal.props.userId,
        newPassword: password,
      });
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const toogleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Content>
      <Title>
        Reset password for <span>{modal.props.email}</span>
      </Title>
      <Form onSubmit={handleSubmit}>
        <Field>
          <LockIcon fill="var(--black)" />
          <Input
            required
            type={showPassword ? 'text' : 'password'}
            value={password}
            placeholder="Enter new password"
            onChange={handleChange}
            name="password"
            autoCorrect="off"
            autoComplete="new-password"
          />
          <IconButton onClick={toogleShowPassword} type="button">
            {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
          </IconButton>
        </Field>

        <Actions isSuccess={success}>
          <StyledButton
            type="button"
            color="secondary"
            disableElevation
            variant="text"
            size="large"
            onClick={handleClose}
            disabled={loading || success}
          >
            Cancel
          </StyledButton>
          <StyledButton
            type="submit"
            color="secondary"
            disableElevation
            size="large"
            variant="contained"
            disabled={loading || success}
            endIcon={
              loading && (
                <CircularProgress size={20} style={{ color: '#fff' }} />
              )
            }
          >
            {loading ? 'Saving' : 'Save'}
          </StyledButton>
        </Actions>
        {success && (
          <Success
            initial={{ y: -5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
          >
            Password has been reseted <span>👌</span>
          </Success>
        )}
      </Form>
    </Content>
  );
}

export default NewPasswordModal;
