import styled from 'styled-components';

export const PlatformType = styled.p`
  margin-bottom: 16px;
  background: #fff;
  display: inline-block;
  padding: 6px;
  width: 90px;
  text-align: center;
  border-radius: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--black);
`;
