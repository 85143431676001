import styled from 'styled-components';
import React from 'react';

export const TagContainer = styled.p`
  display: flex;
  margin-bottom: 4px;
  span {
    width: 50%;
    text-align: left;
    color: #262626;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-right: 12px;
  }

  span:first-child {
    font-weight: 500;
  }
`;

export const Tag = ({label, value, show = true}) => {
  if (!show) return null;

  return <TagContainer>
    <span>{label}</span>
    <span>{value}</span>
  </TagContainer>
}
