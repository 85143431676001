import { motion } from 'framer-motion';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../store';
import { logout } from '../../../store/slices/authSlice';
import HomeIcon from '../icons/HomeIcon';
import LogIcon from '../icons/LogIcon';
import LogoIcon from '../icons/LogoIcon';
import LogoutIcon from '../icons/LogoutIcon';
import UsersIcon from '../icons/UsersIcon';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 32px 32px;
`;
const Logo = styled(motion.button)``;

const AnimatedLogo = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  svg {
    flex-shrink: 0;
    margin-right: 6px;
  }

  span {
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    margin-left: 4px;
  }
`;

const NavBar = styled.nav`
  flex-grow: 1;
  padding-top: 24px;
`;

const Tab = styled(motion.button)`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 12px;
  min-width: 180px;
  transition: background-color 0.2s ease;
  background: ${({ isActive }) =>
    isActive ? 'var(--primary) !important' : 'transparent'};
  color: ${({ isActive }) => (isActive ? '#fff' : 'var(--black)')};

  svg {
    transition: all 0.2s ease;
  }

  span {
    margin-left: 16px;
    font-weight: 600;
  }

  :hover {
    background-color: #dec9e9;
    color: #fff;
    svg {
      fill: #fff;
    }
  }
`;

const LogoutButton = styled(motion.button)`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 12px;
  min-width: 180px;
  transition: background-color 0.2s ease;
  background: ${({ isActive }) =>
    isActive ? 'var(--primary) !important' : 'transparent'};
  color: ${({ isWhite }) => (isWhite ? '#fff' : 'var(--black)')};

  svg {
    transition: all 0.2s ease;
  }

  span {
    margin-left: 16px;
    font-weight: 600;
  }

  :hover {
    background-color: #dec9e9;
    color: #fff;
    svg {
      fill: #fff;
    }
  }
`;

function Sidebar() {
  const auth = useAppSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useAppDispatch();

  if (!auth) {
    return null;
  }

  return (
    <Wrapper>
      <Logo>
        <AnimatedLogo
          initial={{ opacity: 0, scale: 0.2 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
        >
          <LogoIcon />
          <p>
            <span>PlantIn Support</span>
          </p>
        </AnimatedLogo>
      </Logo>
      <NavBar>
        <Link to="/">
          <Tab
            initial={{ opacity: 0, scale: 0.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2 }}
            isActive={location.pathname === '/'}
          >
            <HomeIcon
              fill={location.pathname === '/' ? '#fff' : 'var(--primary)'}
            />
            <span>Home</span>
          </Tab>
        </Link>
        {auth.isAdmin && (
          <Link to="/agents">
            <Tab
              initial={{ opacity: 0, scale: 0.2 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.3 }}
              isActive={location.pathname === '/agents'}
            >
              <UsersIcon
                fill={
                  location.pathname === '/agents' ? '#fff' : 'var(--primary)'
                }
              />
              <span>Team</span>
            </Tab>
          </Link>
        )}
        {auth.isAdmin && (
          <Link to="/actions">
            <Tab
              initial={{ opacity: 0, scale: 0.2 }}
              animate={{ opacity: 1, scale: 1 }}
              j
              transition={{ delay: 0.4 }}
              isActive={location.pathname === '/actions'}
            >
              <LogIcon
                fill={
                  location.pathname === '/actions' ? '#fff' : 'var(--primary)'
                }
              />
              <span>Actions</span>
            </Tab>
          </Link>
        )}
      </NavBar>
      <LogoutButton
        isWhite={location.pathname === '/'}
        initial={{ opacity: 0, scale: 0.2 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.5 }}
        onClick={() => {
          dispatch(logout());
        }}
      >
        <LogoutIcon
          fill={location.pathname === '/' ? '#fff' : 'var(--black)'}
        />
        <span>Log out</span>
      </LogoutButton>
    </Wrapper>
  );
}

export default Sidebar;
