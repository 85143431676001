import React, { useRef, useEffect } from 'react';
import Lottie from 'lottie-react';
import login from '../../../assets/lottie/login.json';

const lottieIconStyles = {
  width: '600px',
};

const AnimatedLogin = () => {
  return <Lottie loop style={lottieIconStyles} animationData={login} />;
};

export default AnimatedLogin;
