import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import boy from '../../../assets/boy.png';
import girl from '../../../assets/girl.png';
import { useAppDispatch } from '../../../store';
import {
  approveActionsAsync,
  rejectActionAsync,
} from '../../../store/slices/actionsSlice';
import AnimatedEmpty from '../../common/lottie/AnimatedEmpty';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 124px;
`;

const List = styled.ul`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
`;

const ActionType = styled.p`
  padding-left: 16px;
  color: var(--primary);
  width: 12%;
  text-transform: capitalize;
`;

const Item = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 2px 2px #eaeaea;
  padding: 12px 0;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const Agent = styled.div`
  display: flex;
  width: 20%;
  align-items: center;
  img {
    width: 38px;
    height: 38px;
    margin-right: 8px;
  }
`;

const Date = styled.div`
  width: 12%;
  p:last-child {
    color: #8f9db6;
  }
`;

const CustomerEmail = styled.p`
  width: 25%;
  padding-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledButton = styled(Button)`
  font-weight: 600;
  margin-right: 8px;
`;

const Actions = styled.div`
  padding: 0 16px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    text-transform: initial;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 8px;
  margin-right: 0px;
  .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: 600;
    color: #8f9db6;
  }
`;

const Toolbar = styled.nav`
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary);
  padding: 16px;
  color: #fff;
  font-weight: 600;
  width: 650px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 10%) 0px 12px 56px, rgb(0 0 0 / 6%) 0px 6px 30px,
    rgb(0 0 0 / 5%) 0px 3px 12px;
`;

const ToolbarButton = styled.button`
  border-radius: 12px;
  padding: 8px 24px;
  color: #fff;
  font-weight: 600;
  background: #fff;
  color: var(--primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 4px;
  }
`;

const ToolbarActions = styled.div`
  margin-left: auto;
  display: flex;
  button {
    margin-left: 8px;
  }
`;

const SelectedActions = styled.div`
  margin-right: 32px;
`;

const Counter = styled.span`
  width: 24px;
  display: inline-block;
  font-size: 18px;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #6d778a;
`;

function ActiveActions({ actions }) {
  const dispatch = useAppDispatch();
  const [selectedActions, setSelectedActions] = useState({});
  const [approveAllLoading, setApproveAllLoading] = useState(false);
  const [approveSelectedLoading, setApproveSelectedLoading] = useState(false);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setSelectedActions((prev) => {
      return { ...prev, [name]: checked };
    });
  };

  const approveOneAction = (action) => async () => {
    const result = await dispatch(approveActionsAsync([action]));
    if (result.meta.requestStatus === 'fulfilled') {
      setSelectedActions({});
    }
  };

  const approveAllActions = async () => {
    setApproveAllLoading(true);
    const result = await dispatch(approveActionsAsync(actions));
    if (result.meta.requestStatus === 'fulfilled') {
      setSelectedActions({});
    }
    setApproveAllLoading(false);
  };

  const approveSelectedActions = async () => {
    const actionsPayload = actions.filter((action) => {
      if (selectedActions[action._id]) {
        return true;
      } else {
        return false;
      }
    });

    if (actionsPayload.length > 0) {
      setApproveSelectedLoading(true);
      const result = await dispatch(approveActionsAsync(actionsPayload));
      if (result.meta.requestStatus === 'fulfilled') {
        setSelectedActions({});
      }
      setApproveSelectedLoading(true);
    }
  };

  const rejectAction = (actionId) => async () => {
    const result = await dispatch(rejectActionAsync(actionId));
    if (result.meta.requestStatus === 'fulfilled') {
      setSelectedActions({});
    }
  };

  const selectedCount = Object.values(selectedActions).filter(
    (checked) => checked
  ).length;

  return (
    <Wrapper>
      {actions.length === 0 && (
        <EmptyWrapper>
          <h2>Currently no actions to process</h2>
          <AnimatedEmpty />
        </EmptyWrapper>
      )}
      <List>
        {actions.map((action) => {
          return (
            <Item key={action._id}>
              <ActionType>{action.actionType.replace('-', ' ')}</ActionType>
              <CustomerEmail>{action.customerEmail} {action.platform ? `(${action.platform})` : ''}</CustomerEmail>
              <Agent>
                <img
                  alt="avatar"
                  src={action.executor.gender === 'male' ? boy : girl}
                />
                <p>{action.executor.nickname || action.executor.email}</p>
              </Agent>
              <Date>
                <p>{dayjs(action.createdAt).format('DD MMMM YYYY')}</p>
                <p>{dayjs(action.createdAt).format('HH:mm')}</p>
              </Date>
              <Actions>
                <StyledButton
                  disableElevation
                  color="primary"
                  variant="contained"
                  onClick={approveOneAction(action)}
                >
                  Approve
                </StyledButton>
                <StyledButton
                  disableElevation
                  color="secondary"
                  variant="outlined"
                  onClick={rejectAction(action._id)}
                >
                  Reject
                </StyledButton>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={action._id}
                      onChange={handleCheckboxChange}
                      checked={selectedActions[action._id] || false}
                    />
                  }
                  label=""
                />
              </Actions>
            </Item>
          );
        })}
      </List>
      {actions.length > 0 && (
        <Toolbar>
          <SelectedActions>
            <p>
              Selected actions: <Counter>{selectedCount}</Counter>
            </p>
          </SelectedActions>
          <ToolbarActions>
            {selectedCount > 0 && (
              <ToolbarButton
                size="large"
                disableElevation
                color="primary"
                variant="contained"
                onClick={approveSelectedActions}
              >
                <span>Approve Selected</span>
                {approveSelectedLoading && (
                  <CircularProgress size={24} color="primary" />
                )}
              </ToolbarButton>
            )}
            <ToolbarButton
              size="large"
              disableElevation
              color="primary"
              variant="contained"
              onClick={approveAllActions}
            >
              <span>Approve all</span>
              {approveAllLoading && (
                <CircularProgress size={24} color="primary" />
              )}
            </ToolbarButton>
          </ToolbarActions>
        </Toolbar>
      )}
    </Wrapper>
  );
}

export default ActiveActions;
