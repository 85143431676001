import React, { useRef, useEffect } from 'react';
import Lottie from 'lottie-react';
import empty from '../../../assets/lottie/empty.json';

const lottieIconStyles = {
  width: '400px',
  height: '400px',
};

const AnimatedEmpty = () => {
  return <Lottie loop style={lottieIconStyles} animationData={empty} />;
};

export default AnimatedEmpty;
