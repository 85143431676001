import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import girl from '../../../../assets/girl.png';
import AroowBackIcon from '../../icons/ArrowBackIcon';
import SelectedIcon from '../../icons/SelectedIcon';

const avatars = [
  require('../../../../assets/batman.svg'),
  require('../../../../assets/boy-1.svg'),
  require('../../../../assets/boy.png'),
  require('../../../../assets/boy.svg'),
  require('../../../../assets/girl.png'),
  require('../../../../assets/girl.svg'),
  require('../../../../assets/kitty.svg'),
  require('../../../../assets/man-1.svg'),
  require('../../../../assets/man.svg'),
  require('../../../../assets/man2.svg'),
  require('../../../../assets/man-1.svg'),
  require('../../../../assets/superhero.svg'),
];

const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 28px 32px 32px 32px;
`;

const Header = styled.header`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BackButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 4px 0;

  span {
    margin-left: 8px;
    font-size: 14px;
    text-transform: uppercase;
  }

  svg {
    transition: 0.3s ease;
  }

  :hover {
    svg {
      transform: translateX(-3px);
    }
  }
`;

const GalleryWrapper = styled.div`
  overflow-y: auto;
  padding-top: 6px;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  height: calc(100% - 24px);
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`;

const Item = styled.button`
  cursor: pointer;
  position: relative;

  :hover {
    transform: scale(1.02);
  }
`;

const Avatar = styled.img``;

const SelectionIndicator = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 24px;
  width: 24px;
`;

const Submit = styled(motion.button)`
  background: var(--primary);
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
`;

const HeightUtil = styled.div`
  height: 32px;
`;

function AvatarSelector({ closeGallery }) {
  const [selectedAvatar, setSelectedAvatar] = useState(false);
  return (
    <Wrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Header>
        <BackButton onClick={closeGallery}>
          <AroowBackIcon />
          <span>back</span>
        </BackButton>
        {selectedAvatar !== false && (
          <Submit
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            type="button"
          >
            Submit
          </Submit>
        )}
      </Header>
      <GalleryWrapper>
        <Gallery>
          {avatars.map((_, index) => {
            return (
              <Item
                key={index}
                type="button"
                onClick={() => {
                  setSelectedAvatar(index);
                }}
              >
                <Avatar src={girl} />
                {selectedAvatar !== false && index === selectedAvatar && (
                  <SelectionIndicator>
                    <SelectedIcon fill="var(--primary)" />
                  </SelectionIndicator>
                )}
              </Item>
            );
          })}
        </Gallery>
        <HeightUtil />
      </GalleryWrapper>
    </Wrapper>
  );
}

export default AvatarSelector;
