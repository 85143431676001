import React, { useState } from 'react';
import styled from 'styled-components';
import EditIcon from '../../icons/EditIcon';

import boy from '../../../../assets/boy.png';
import girl from '../../../../assets/girl.png';
import { Tooltip } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';
import AvatarSelector from './AvatarSelector';

const Wrapper = styled.div`
  min-width: 350px;
  display: flex;
  padding: 32px;
  justify-content: center;
  position: relative;
`;

const Title = styled.h2`
  text-align: center;
  color: #3b3b3b;
  font-size: 22px;
  margin-bottom: 24px;
  margin-bottom: 64px;
`;

const InfoFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AvatarWrapper = styled.div`
  margin-bottom: 32px;
  position: relative;
`;

const EditIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;

  :hover {
    transform: scale(1.1);
  }
`;

const Avatar = styled(motion.img)`
  object-fit: cover;
  object-position: center;
`;

const ToogleWrapper = styled.div``;

const Toogle = styled.div`
  display: inline-flex;
  background: #b185db;
  border-radius: 12px;
`;

const ToogleButton = styled.button`
  display: flex;
  border-radius: 12px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 36px;
  cursor: pointer;
  font-weight: 600;
  color: #fff;
  transition: all 0.2s ease;
  background-color: ${({ active }) => (active ? 'var(--primary)' : '#b185db')};
`;

function UserIdentity({ onSelect }) {
  const [gender, setGender] = useState('female');
  const [gallery, setGallery] = useState(false);

  const toogleGender = (e) => {
    setGender(e.target.name);
    onSelect(e.target.name);
  };

  const openGallery = () => {
    setGallery(true);
  };

  const closeGallery = () => {
    setGallery(false);
  };

  return (
    <Wrapper>
      {gallery ? (
        <AvatarSelector closeGallery={closeGallery} />
      ) : (
        <InfoFrame>
          <Title>Create new hero 🚀</Title>
          <AvatarWrapper>
            <AnimatePresence exitBeforeEnter>
              <Avatar
                key={gender}
                width="200px"
                height="200px"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                src={gender === 'female' ? girl : boy}
                alt="user avatar"
              />
            </AnimatePresence>
            {/* <Tooltip placement="top" title="Choose from gallery">
              <EditIconButton onClick={openGallery}>
                <EditIcon />
              </EditIconButton>
            </Tooltip> */}
          </AvatarWrapper>
          <ToogleWrapper>
            <Toogle>
              <ToogleButton
                name="female"
                type="button"
                active={gender === 'female'}
                onClick={toogleGender}
              >
                Female
              </ToogleButton>
              <ToogleButton
                name="male"
                type="button"
                active={gender === 'male'}
                onClick={toogleGender}
              >
                Male
              </ToogleButton>
            </Toogle>
          </ToogleWrapper>
        </InfoFrame>
      )}
    </Wrapper>
  );
}

export default UserIdentity;
