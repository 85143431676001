import React from 'react';
import styled from 'styled-components';
import { Platform } from '../platform/Platform';

const Wrapper = styled.div``;

const Title = styled.h2`
  font-size: 22px;
  margin-bottom: 12px;
`;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-bottom: 24px;
`;


function Platforms({ data }) {
  return (
    <Wrapper>
      <Title>Platforms (Amplitude source)</Title>
      <Container>
        {data
          .filter(({ platform, amp_props }) => {
            return !!amp_props;
          })
          .map(({ platform, amp_props }) => <Platform key={platform} platform={platform}
                                                      deviceInformation={amp_props} />)}
      </Container>
    </Wrapper>
  );
}

export default Platforms;
