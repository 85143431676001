import jwt_decode from 'jwt-decode';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, {
  removeUserTokenFromAxios,
  saveUserTokenToAxios,
} from '../../lib/axios';

const initialState = {
  _id: '',
  isAdmin: false,
  firstName: '',
  lastName: '',
  email: '',
  avatar: '',
};

const LOGOUT = () => {
  localStorage.removeItem('token');
  removeUserTokenFromAxios();
  return null;
};

export const initAuthAsync = createAsyncThunk(
  'auth/init-auth',
  async (payload, { rejectWithValue }) => {
    try {
      const cachedToken = localStorage.getItem('token');

      if (cachedToken) {
        const response = await axios({
          method: 'get',
          url: '/user/info',
          headers: {
            Authorization: cachedToken,
          },
        });
        saveUserTokenToAxios(cachedToken);
        return response.data;
      }
      return null;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const loginAsync = createAsyncThunk(
  'auth/login',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/user/login',
        data: payload,
      });

      const { token } = response.data;
      localStorage.setItem('token', token);
      saveUserTokenToAxios(token);
      const user = jwt_decode(token);

      return user;
    } catch (err) {
      return rejectWithValue({ message: 'error' });
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: { logout: LOGOUT },
  extraReducers: (builder) => {
    builder.addCase(initAuthAsync.fulfilled, (state, { payload }) => {
      return payload;
    });
    builder.addCase(loginAsync.fulfilled, (state, { payload }) => {
      return payload;
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
