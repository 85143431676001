import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import LoginForm from './LoginForm';
import AnimatedLogin from '../common/lottie/AnimatedLogin';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100vh;
  background: #dec9e9;
`;

const AnimationWrapper = styled.div`
  padding-right: 64px;
`;

const Content = styled(motion.div)`
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
  position: relative;
`;

const Title = styled.h1`
  margin-bottom: 24px;
  color: var(--black);

  span {
    color: var(--primary);
  }
`;

function Loginpage() {
  return (
    <Wrapper>
      <Content>
        <AnimationWrapper>
          <Title>
            Let&apos;s make our users happy <br />
            <span>together </span>😍
          </Title>
          <AnimatedLogin />
        </AnimationWrapper>
        <LoginForm />
      </Content>
    </Wrapper>
  );
}

export default Loginpage;
