import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockIcon from '../common/icons/LockIcon';
import HidePasswordIcon from '../common/icons/HidePasswordIcon';
import ShowPasswordIcon from '../common/icons/ShowPasswordIcon';
import ProfileIcon from '../common/icons/ProfileIcon';
import { useAppDispatch } from '../../store';
import { loginAsync } from '../../store/slices/authSlice';

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.35);
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  padding: 32px;
  border-radius: 16px;
  position: relative;
  transition: transform 0.6s ease;
  transform: ${({ hide }) => hide && 'scale(0.9)'};
`;

const Title = styled.h2`
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 24px;
  color: var(--black);

  span {
    color: var(--primary);
  }
`;

const Form = styled.form`
  width: 436px;
`;
const Field = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 16px;
  margin-bottom: 20px;
  display: flex;
  padding: 8px 18px;
  align-items: center;
  border: 1px solid;
  transition: border-color 0.3s ease;
  border-color: ${({ focus }) => (focus ? 'var(--primary)' : 'transparent')};
  background: rgba(255, 255, 255, 0.4);
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
const Input = styled.input`
  width: 100%;
  display: inline-block;
  height: 100%;

  border-radius: inherit;
  outline: none;
  font-size: 18px;
  line-height: 21px;
  color: var(--black);
  margin-left: 8px;
  caret-color: #fff;
  background-color: transparent;
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: var(--black);
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  ::placeholder {
    color: var(--primary);
  }
`;

const IconButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Submit = styled.button`
  width: 100%;
  text-align: center;
  line-height: 1;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
`;

const ErrorMessage = styled(motion.p)`
  padding: 20px;
  background: var(--error);
  border-radius: 16px;
  margin-bottom: 24px;
  color: #fff;
`;

function LoginForm() {
  const history = useHistory();
  const [hide, setHide] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [focus, setFocus] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleEmail = (e) => {
    if (error) {
      setError(false);
    }
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    if (error) {
      setError(false);
    }
    setPassword(e.target.value);
  };

  const handleFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleBlur = (e) => {
    setFocus('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await dispatch(loginAsync({ email, password }));
    setLoading(false);
    if (response.meta.requestStatus === 'rejected') {
      setError(true);
    } else {
      setHide(true);
      setTimeout(() => {
        history.push('/');
      }, 500);
    }
  };

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((prev) => !prev);
  };

  return (
    <Wrapper hide={hide}>
      <Title>
        Login to <span>PlantIn Support</span>
      </Title>
      <Form onSubmit={handleSubmit}>
        <Field focus={focus === 'email'}>
          <ProfileIcon fill="var(--black)" />
          <Input
            required
            value={email}
            placeholder="Email"
            onChange={handleEmail}
            name="email"
            type="email"
            autoCorrect="off"
            autoCapitalize="off"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Field>
        <Field focus={focus === 'password'}>
          <LockIcon fill="var(--black)" />
          <Input
            required
            type={showPassword ? 'text' : 'password'}
            value={password}
            placeholder="Password"
            onChange={handlePassword}
            name="password"
            autoCorrect="off"
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoComplete="new-password"
          />
          <IconButton onClick={handleClickShowPassword} type="button">
            {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
          </IconButton>
        </Field>
        <AnimatePresence>
          {error && (
            <ErrorMessage
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
            >
              Email or password is incorrect
            </ErrorMessage>
          )}
        </AnimatePresence>
        <Submit type="submit">
          <span>Login</span>
          {loading && (
            <CircularProgress
              size={24}
              style={{ color: '#fff', marginLeft: '8px' }}
            />
          )}
        </Submit>
      </Form>
    </Wrapper>
  );
}

export default LoginForm;
