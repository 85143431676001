import React from 'react';
import styled from 'styled-components';
import { Image } from './Image';

const ImagesContainer = styled.div`
  width: 98%;
  display: grid;
  grid-gap: 12px;
  margin-bottom: 12px;
  grid-template-columns: 1fr 1fr 1fr;

  img {
    border-radius: 12px;
  }
`;

export const Images = ({images}) => {
  return <ImagesContainer>
    {images.map(image => <Image key={image} src={image}/>)}
  </ImagesContainer>
}
