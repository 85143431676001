import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import HomePage from './components/home/HomePage';
import LoginPage from './components/login/LoginPage';
import { useAppDispatch } from './store/index';
import { initAuthAsync } from './store/slices/authSlice';
import Layout from './components/common/Layout';
import Agents from './components/agents/Agents';
import ModalManager from './components/common/modals/Modalmanager';
import ActionsPage from './components/actions/ActionsPage';
import UserInfo from './components/home/user-info/UserInfo';

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 100;
  transition: opacity 0.4s linear;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileBlocker = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--primary);
  text-align: center;
  font-size: 24px;

  @media (min-width: 600px) {
    display: none;
  }
`;

function App() {
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState(false);
  const [authready, setAuthReady] = useState(false);

  useEffect(() => {
    window.addEventListener('load', (event) => {
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    const initAuth = async () => {
      const authResult = await dispatch(initAuthAsync());
      if (authResult.meta.requestStatus === 'fulfilled') {
        setAuthReady(true);
      }
    };
    initAuth();
  }, [dispatch]);

  return (
    <div>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>

          <Route path="/agents">
            <Layout>
              <Agents />
            </Layout>
          </Route>
          <Route path="/actions">
            <Layout>
              <ActionsPage />
            </Layout>
          </Route>
          <Route path='/users/:platform/:userId'>
            <Layout>
              <UserInfo />
            </Layout>
          </Route>
          <Route path="/">
            <Layout>
              <HomePage />
            </Layout>
          </Route>
        </Switch>
      </Router>
      <ModalManager />
      <AnimatePresence initial={false}>
        {!loaded && !authready && (
          <Overlay
            key="overlay"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          />
        )}
      </AnimatePresence>
      <MobileBlocker>
        <p>
          Sorry <span>😔</span>
          <br /> platform currently not working on mobile devices
        </p>
      </MobileBlocker>
    </div>
  );
}

export default App;
