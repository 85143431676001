import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../lib/api';

const initialState = [];

export const getActionsAsync = createAsyncThunk(
  'actions/get-actions',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Api.getActions();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const approveActionsAsync = createAsyncThunk(
  'actions/approve-actions',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Api.approveMultipleActions(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const rejectActionAsync = createAsyncThunk(
  'actions/reject-action',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Api.rejectAction(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const authSlice = createSlice({
  name: 'actions',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getActionsAsync.fulfilled, (state, { payload }) => {
      return payload;
    });
    builder.addCase(approveActionsAsync.fulfilled, (state, { payload }) => {
      const indexes = [];
      payload.forEach((id) => {
        indexes.push(state.findIndex((action) => action._id === id));
      });
      indexes.forEach((index) => (state[index].status = 1));
    });
    builder.addCase(rejectActionAsync.fulfilled, (state, { meta }) => {
      const index = state.findIndex((action) => action._id === meta.arg);
      state[index].status = 2;
    });
  },
});

export default authSlice.reducer;
