import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import AnimatedSearching from '../common/lottie/AnimatedSearching';
import AnimatedNotFound from '../common/lottie/AnimatedNotFound';
import Api from '../../lib/api';
import UserInfo from './user-info/UserInfo';
import { useAppSelector } from '../../store';
import SearchWidget from './SearchWidget';
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  padding-top: 16px;
  padding-left: 300px;
  min-height: 100vh;
  transition: all 0.8s;
  position: relative; ;
`;

const SearchWrapper = styled(motion.div)``;

const AnimationWrapper = styled.div`
  margin-top: 64px;

  div {
    margin: 0 auto;
  }

  h2 {
    color: #8f9db6;
    margin-bottom: 24px;
    text-align: center;
    font-weight: 700;
  }

  span {
    color: var(--primary);
  }
`;

const LoadingWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: var(--primary);
  }
`;

const Wave = styled(motion.svg)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  @media (max-height: 750px) {
    bottom: -64px;
  }
`;

function HomePage() {
  const auth = useAppSelector((state) => state.auth);
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const history = useHistory();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!auth) {
    return <Redirect to="/login" />;
  }

  const fetchUser = async (value, platform) => {
    try {
      setLoading(value);
      const response = await Api.fetchUserInfo(value, platform);
      const userId = response.data.userInfo.user_id;
      history.push(`/users/${platform}/${userId}`);
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setNotFound(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const renderSearchResult = () => {
    if (result) {
      return;
    }
    if (loading) {
      return (
        <LoadingWrapper>
          <h2>
            Looking for <span>{loading}</span>
          </h2>
          <AnimatedSearching />
        </LoadingWrapper>
      );
    } else if (notFound) {
      return (
        <AnimationWrapper>
          <h2>Sorry, we can&apos;t find such user...</h2>
          <AnimatedNotFound />
        </AnimationWrapper>
      );
    } else {
      return null;
    }
  };

  const handleBackToSearch = () => {
    setResult(false);
  };

  return (
    <Wrapper>
      {result ? (
        <UserInfo data={result} handleBackToSearch={handleBackToSearch} />
      ) : (
        <>
          <SearchWrapper
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
          >
            <SearchWidget
              loading={loading}
              notFound={notFound}
              fetchUser={fetchUser}
              setNotFound={setNotFound}
              setLoading={setLoading}
            />
          </SearchWrapper>
          {renderSearchResult()}
        </>
      )}
      <AnimatePresence>
        {!result && !loading && (
          <Wave
            initial={{ opacity: 0, y: 120 }}
            animate={{ opacity: 1, y: 80 }}
            exit={{ opacity: 0, y: 120 }}
            transition={{ duration: 0.4 }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="var(--primary)"
              fillOpacity="1"
              d="M0,32L48,53.3C96,75,192,117,288,122.7C384,128,480,96,576,106.7C672,117,768,171,864,186.7C960,203,1056,181,1152,149.3C1248,117,1344,75,1392,53.3L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </Wave>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

export default HomePage;
