import styled from 'styled-components';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useAppDispatch } from '../../../store';
import { openModal } from '../../../store/slices/uiSlice';
import { ListItem } from '../../common/list-item/ListItem';

const PaymentContainer = styled.div`
  align-self: stretch;
  min-width: 450px;
  display: flex;
  flex-direction: column;

  background-color: #b185db;
  flex-shrink: 0;
  color: #fff;
  border-radius: 16px;
  padding: 16px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }

  li span:first-child {
    font-weight: 600;
    font-size: 14px;
    color: var(--black);
    text-transform: uppercase;
  }

  li span:last-child {
    font-size: 16px;
    color: #fff;
  }

  li:nth-last-child(2) {
    margin-bottom: 15px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 8px;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: auto;
`;

const dateStringToTimestamp = (date) => {
  if (!date) return Date.now();

  return new Date(date).getTime()
}

const getPaymentStatus = (expiresDate) => {
  return dateStringToTimestamp(expiresDate) > Date.now() ? 'active' :  'inactive'
}

export const Payment = ({purchase}) => {
  const dispatch = useAppDispatch();
  const [success, setSuccess] = useState(false);

  const {purchase_name, purchase_date, expires_date, payment_provider, paymentCountry, status, platform, order_id, user_email, user_id, subscription_id, is_purchase, is_trial} = purchase;
  const isValidExpiresDate = expires_date !== 'Invalid Date';
  const statusByDate = getPaymentStatus(expires_date);

  const handleModal = (actionType) => () => {
    dispatch(
      openModal({
        type: 'user-action',
        actionType,
        order_id,
        userEmail: user_email,
        userId: user_id,
        subscription_id,
        platform,
        onSuccess: () => {
          setSuccess(true);
        },
        onError: () => {
          console.log('erorr');
        },
      })
    );
  };

  return <PaymentContainer>
    <ListItem label="Type" value={purchase_name}/>
    {!is_purchase && <ListItem label="Trial" value={is_trial ? 'Yes' : 'No'}/>}
    {!is_purchase && <ListItem label="Status" value={status || statusByDate}/>}
    <ListItem label="Purchase date" value={purchase_date} />
    {!is_purchase && <ListItem label="Expires in" value={expires_date} show={isValidExpiresDate}/>}
    <ListItem label="Payment provider" value={payment_provider} />
    <ListItem label="Payment Country" value={paymentCountry} show={!!paymentCountry}/>
    
    {!success && order_id && ["approved", "settle_ok", "partial_settled"].includes(status) && (
      <StyledButton
        variant="outlined"
        color="primary"
        disableElevation
        disableElavation
        onClick={handleModal('refund-payment-solid')}
      >
        Refund payment
      </StyledButton>
    )}
  </PaymentContainer>
}