import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../lib/axios';

const initialState = [];

export const getAllUsersAsync = createAsyncThunk(
  'users/getAllUsers',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'get',
        url: '/user',
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const editUserInfoAsync = createAsyncThunk(
  'users/editUserInfo',
  async (payload, { rejectWithValue }) => {
    const { userId, values } = payload;

    try {
      const response = await axios({
        method: 'put',
        url: `/user/${userId}`,
        data: values,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const createUserAsync = createAsyncThunk(
  'users/createUser',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'post',
        url: `/user`,
        data: payload,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  'users/deleteUser',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'delete',
        url: `/user/${payload.userId}`,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsersAsync.fulfilled, (state, { payload }) => {
      return payload;
    });
    builder.addCase(editUserInfoAsync.fulfilled, (state, { payload }) => {
      const index = state.findIndex((user) => user._id === payload._id);
      if (index !== -1) state[index] = payload;
    });
    builder.addCase(createUserAsync.fulfilled, (state, { payload }) => {
      state.push(payload);
    });
    builder.addCase(deleteUserAsync.fulfilled, (state, { payload }) => {
      const index = state.findIndex((user) => user._id === payload.data._id);
      if (index !== -1) state[index].isDeleted = true;
    });
  },
});

// export const {} = usersSlice.actions;

export default usersSlice.reducer;
