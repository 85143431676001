import React, { useState } from 'react';
import styled from 'styled-components';

const Form = styled.form`
  width: 600px;
  height: 64px;
  display: flex;
  border-radius: 16px;
  background-color: #ffffff;

  @media (max-width: 1500px) {
    width: 500px;
  }
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 16px;
  outline: none;
  font-size: 18px;
  font-weight: 600;
  caret-color: var(--primary);
  background-color: transparent;

  ::placeholder {
    color: #8f9db6;
  }
`;

const SubmitButton = styled.button`
  flex-shrink: 0;
  height: 100%;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  background-color: #6247aa;
  transition: all 0.3s ease;
  border-radius: 0px 16px 16px 0px;

  svg {
    width: 28px;
    height: 28px;
  }
`;

function Search({ loading, fetchUser }) {
  const [value, setValue] = useState('');
  const [platform, setPlatform] = useState('plantin');

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handlePlatformSelect = (e) => {
    setPlatform(e.target.value);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchUser(value, platform);
  };

  return (
    <div>
      <Form loading={loading ? 'true' : 'false'} onSubmit={handleSubmit}>
        <Input
          required
          placeholder="Enter user email or id here..."
          autoComplete="off"
          value={value}
          onChange={handleChange}
        />
        <SubmitButton type="submit">Search</SubmitButton>
      </Form>
      <div className={"radio-group"}>
        <div className="radio">
          <label>
            <input type="radio" value="plantin"
                   checked={platform === 'plantin'}
                   onChange={handlePlatformSelect} />
            PlantIn
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="carl"
                   checked={platform === 'carl'}
                   onChange={handlePlatformSelect} />
            Carl
          </label>
        </div>
      </div>
    </div>
  );
}

export default Search;
