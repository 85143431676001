import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import DeleteIcon from '../common/icons/DeleteIcon';
import PasswordIcon from '../common/icons/PasswordIcon';
import { useAppDispatch } from '../../store';
import { openModal } from '../../store/slices/uiSlice';

import AgentActionsHistory from './AgentActionsHistory';
import ArrowBackIcon from '../common/icons/ArrowBackIcon';
import { motion } from 'framer-motion';
import boy from '../../assets/boy.png';
import girl from '../../assets/girl.png';

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 20;
  overflow: auto;
`;

const ContentContainer = styled.div`
  padding: 64px;
`;

const Content = styled.div`
  display: flex;
`;

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  width: 300px;
  flex-shrink: 0;
  margin-right: 64px;
  position: sticky;
  top: 0;
`;

const StyledButton = styled(Button)`
  text-transform: initial;
  border-radius: 14px;
  padding: 10px;
  margin-bottom: 12px;
`;

const BackButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 32px;

  span {
    margin-left: 8px;
    font-size: 16px;
    text-transform: uppercase;
  }

  svg {
    transition: 0.3s ease;
  }

  :hover {
    svg {
      transform: translateX(-5px);
    }
  }
`;

const Avatar = styled.div`
  padding: 16px;
  text-align: center;
  margin-bottom: 12px;

  img {
    max-width: 180px;
  }
`;

const Info = styled.div`
  text-align: center;
  font-weight: 600;
  p {
    font-size: 14px;
    margin-bottom: 4px;
  }

  p:first-child {
    font-size: 16px;
  }

  p:last-child {
    border-radius: 8px;
    color: var(--primary);
  }

  margin-bottom: 32px;
`;

function AgentInfo({ agent, removeSelect }) {
  const dispatch = useAppDispatch();

  const openDeleteModal = () => {
    dispatch(openModal({ type: 'delete-agent', props: { id: agent._id } }));
  };

  const openNewPasswordModal = () => {
    dispatch(
      openModal({
        type: 'new-password',
        props: { userId: agent._id, email: agent.email },
      })
    );
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        removeSelect();
      }
    };

    document.addEventListener('keydown', handleEscape, false);

    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [removeSelect]);

  return (
    <Wrapper key={agent._id} initial={{ y: 20 }} animate={{ y: 0 }}>
      <ContentContainer>
        <Content>
          <Sidebar>
            <BackButton onClick={removeSelect}>
              <ArrowBackIcon />
              <span>back to agents</span>
            </BackButton>
            <Avatar>
              <img alt="avatar" src={agent.gender === 'male' ? boy : girl} />
            </Avatar>
            <Info>
              <p>
                {agent.firstName} {agent.lastName}
              </p>
              <p>{agent.email}</p>
            </Info>
            <StyledButton
              startIcon={<PasswordIcon />}
              disableElevation
              color="primary"
              variant="outlined"
              onClick={openNewPasswordModal}
            >
              Reset Password
            </StyledButton>
            <StyledButton
              startIcon={<DeleteIcon />}
              disableElevation
              color="primary"
              variant="outlined"
              onClick={openDeleteModal}
            >
              Delete User
            </StyledButton>
          </Sidebar>
          <AgentActionsHistory agent={agent} />
        </Content>
      </ContentContainer>
    </Wrapper>
  );
}

export default AgentInfo;
