import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import boy from '../../assets/boy.png';
import girl from '../../assets/girl.png';

const List = styled.ul`
  margin-bottom: 64px;
  padding-right: 64px;
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const AggentAvatar = styled.img`
  margin-right: 8px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

const Name = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2px;
`;

const Email = styled.p`
  font-size: 14px;
  color: #fff;
`;

const CardWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  background: ${({ isSelected }) =>
    isSelected ? 'var(--primary)' : '#b185db'};
  padding: 16px;
  border-radius: 28px;
  cursor: pointer;
  transition: transform 0.2s ease;

  :hover {
    transform: scale(0.98) !important;
  }
`;

const Info = styled.div`
  color: #fff;
`;

const NickName = styled.p`
  padding: 2px 12px;
  border-radius: 8px;
  font-weight: 500;
  margin-bottom: 6px;
  display: inline-flex;
  background: rgba(255, 255, 255, 0.2);
`;

const cards = {
  initial: { opacity: 0, scale: 0.9 },
  animate: (i) => ({
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.02 * i,
    },
  }),
};

function AgentsList({ users, selectedUser, handleSelect }) {
  return (
    <List>
      {users
        .filter((user) => !user.isAdmin && !user.isDeleted)
        .map((user, index) => {
          const { email, nickname, gender, firstName, lastName } = user;
          return (
            <CardWrapper
              custom={index}
              initial="initial"
              animate="animate"
              variants={cards}
              isSelected={selectedUser === email}
              key={email}
              onClick={handleSelect(email)}
            >
              <AggentAvatar alt="avatar" src={gender === 'male' ? boy : girl} />
              <Info>
                {nickname && <NickName>{nickname}</NickName>}
                <Name>
                  {firstName} {lastName}
                </Name>
                <Email>{email}</Email>
              </Info>
            </CardWrapper>
          );
        })}
    </List>
  );
}

export default AgentsList;
