import React from 'react';
import { Card } from './Card';
import { Meta } from './Meta';
import { Tag } from './Tag';
import dayjs from 'dayjs';
import { PDFLink } from './PdfLink';
import { Message } from './Message';
import { Images } from './Images';

export const ExpertHelp = ({expertHelp}) => {
  const {
    createdAt,
    sendedAt,
    email,
    message,
    images,
    requestId,
    status,
    isPending,
    pdfUrl,
  } = expertHelp;

  const expertHelpStatus = isPending ? 'Pending' : status === 0 ? 'In progress' : 'Finished';

  const created = dayjs(createdAt).format('DD.MM.YY, HH:mm');
  const finished = dayjs(sendedAt).format('DD.MM.YY, HH:mm');

  return <Card>
    <Meta>
      <Tag label="Request id:" value={requestId} />
      <Tag label="Email:" value={email} />
      <Tag label="Status:" value={expertHelpStatus}/>
      <Tag label="Created at:" value={created} />
      <Tag label="Finished at:" value={finished} show={!!sendedAt}/>
      <PDFLink href={pdfUrl} show={!!pdfUrl} />
    </Meta>
    <Message message={message} />
    <Images images={images} />
  </Card>
}



