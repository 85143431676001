import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { motion } from 'framer-motion';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAppDispatch } from '../../../../store';
import { deleteUserAsync } from '../../../../store/slices/usersSlice';

const Wrapper = styled.div`
  background: #fff;
  padding: 32px;
  user-select: none;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 10%) 0px 12px 56px, rgb(0 0 0 / 6%) 0px 6px 30px,
    rgb(0 0 0 / 5%) 0px 3px 12px;
`;

const Actions = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: flex-end;
  button:last-child {
    margin-left: 12px;
  }

  button {
    min-width: 100px;
  }
`;

const ErrorMessage = styled(motion.p)`
  padding: 20px;
  background: var(--error);
  border-radius: 12px;
  margin-top: 10px;
  color: #fff;
  font-weight: 600;
`;

const SuccessMessage = styled(ErrorMessage)`
  background: var(--primary);
`;

const Message = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
`;

function DeleteUserModal({ modal, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const dispatch = useAppDispatch();

  const deleteUser = async () => {
    setLoading(true);
    const response = await dispatch(
      deleteUserAsync({ userId: modal.props.id })
    );
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      handleClose(false);
    }, 1000);
  };

  return (
    <Wrapper>
      {!success && (
        <Message>
          Are you sure you want to permanently delete this agent?
        </Message>
      )}
      {error && (
        <ErrorMessage
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
        >
          {error}
        </ErrorMessage>
      )}
      {success && (
        <SuccessMessage
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
        >
          User has been deleted <span>👌</span>
        </SuccessMessage>
      )}
      {!success && (
        <Actions>
          <Button
            color="secondary"
            size="large"
            variant="text"
            disableElevation
            type="button"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
            disableElevation
            disabled={loading}
            onClick={deleteUser}
            endIcon={loading && <CircularProgress size={20} color="primary" />}
          >
            Delete
          </Button>
        </Actions>
      )}
    </Wrapper>
  );
}

export default DeleteUserModal;
