import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import HidePasswordIcon from '../../icons/HidePasswordIcon';
import ShowPasswordIcon from '../../icons/ShowPasswordIcon';
import Button from '@material-ui/core/Button';
import { useAppDispatch } from '../../../../store';
import { createUserAsync } from '../../../../store/slices/usersSlice';
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledForm = styled.form`
  display: flex;
  width: 350px;
  padding: 32px;
  position: relative;
  flex-direction: column;
`;

const Field = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  display: inline-block;
  color: #8f9db6;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  border: 1px solid;
  background-color: #f1f1f2;
  transition: border-color 0.3s ease;
  border-color: ${({ focus }) => (focus ? 'var(--primary)' : 'transparent')};
`;

const Input = styled.input`
  width: 100%;
  display: inline-block;
  height: 100%;
  border-radius: inherit;
  outline: none;
  font-size: 16px;
  line-height: 21px;
  color: #242424;
  margin-left: 8px;
  background-color: transparent;
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #242424;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  ::placeholder {
    color: #8f9db6;
    font-size: 14px;
  }
`;

const IconButton = styled.button`
  cursor: pointer;
  width: 56px;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`;

const Actions = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: flex-end;
  button:last-child {
    margin-left: 12px;
  }
`;

const ErrorMessage = styled(motion.p)`
  padding: 20px;
  background: var(--error);
  border-radius: 12px;
  margin-bottom: 24px;
  margin-top: 10px;
  color: #fff;
  font-weight: 600;
`;

const SuccessMessage = styled(ErrorMessage)`
  background: var(--primary);
`;

function Form({ onSubmit, handleClose, error, success, loading }) {
  const [focus, setFocus] = useState();
  const [showPassword, setShowPassword] = useState();
  const [values, setValues] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    nickname: '',
  });

  const handleChange = (type) => (event) => {
    setValues({ ...values, [type]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleBlur = (e) => {
    setFocus('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(values);
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Field focus={focus === 'email'}>
        <Label>Email</Label>
        <InputWrapper>
          <Input
            required
            value={values.email}
            placeholder="example@gen.tech"
            onChange={handleChange('email')}
            name="email"
            type="email"
            autoCorrect="off"
            autoCapitalize="off"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </InputWrapper>
      </Field>
      <Field focus={focus === 'nickname'}>
        <Label>Nick Name</Label>
        <InputWrapper>
          <Input
            required
            value={values.nickname}
            placeholder=""
            onChange={handleChange('nickname')}
            name="nickname"
            type="text"
            autoCorrect="off"
            autoCapitalize="off"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </InputWrapper>
      </Field>
      <Field focus={focus === 'fisrtName'}>
        <Label>First name</Label>
        <InputWrapper>
          <Input
            value={values.firstName}
            placeholder=""
            onChange={handleChange('firstName')}
            name="firstName"
            type="text"
            autoCorrect="off"
            autoCapitalize="off"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </InputWrapper>
      </Field>
      <Field focus={focus === 'lastName'}>
        <Label>Last name</Label>
        <InputWrapper>
          <Input
            value={values.lastName}
            placeholder=""
            onChange={handleChange('lastName')}
            name="lastName"
            type="text"
            autoCorrect="off"
            autoCapitalize="off"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </InputWrapper>
      </Field>
      <Field focus={focus === 'password'}>
        <Label>Password</Label>
        <InputWrapper>
          <Input
            required
            value={values.password}
            placeholder=""
            onChange={handleChange('password')}
            name="password"
            type={showPassword ? 'text' : 'password'}
            autoCorrect="off"
            autoCapitalize="off"
            autoComplete="new-password"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <IconButton onClick={handleClickShowPassword} type="button">
            {showPassword ? (
              <HidePasswordIcon fill="#8f9db6" />
            ) : (
              <ShowPasswordIcon fill="#8f9db6" />
            )}
          </IconButton>
        </InputWrapper>
      </Field>
      {error && (
        <ErrorMessage
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
        >
          {error}
        </ErrorMessage>
      )}
      {success && (
        <SuccessMessage
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
        >
          User has been created <span>👌</span>
        </SuccessMessage>
      )}
      <Actions>
        <Button
          color="primary"
          size="large"
          variant="text"
          disableElevation
          type="button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disableElevation
          disabled={loading}
          endIcon={loading && <CircularProgress size={20} color="primary" />}
        >
          {loading ? 'Creating' : 'Create'}
        </Button>
      </Actions>
    </StyledForm>
  );
}

export default Form;
