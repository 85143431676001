import { Status } from '../constants/status';

const isCancelled = (payPalPayment) => {
  return payPalPayment.status === Status.CANCELLED;
};
const getLastTransaction = (payPalPayments) => {
  return payPalPayments[payPalPayments.length - 1];
};

export const hasTransactions = (payPalPayments) => {
  if (!Array.isArray(payPalPayments)) return false;

  return payPalPayments.length !== 0;
}

export const isLastTransactionCancelled = (payPalPayments) => {
  return isCancelled(getLastTransaction(payPalPayments));
};

export const getActiveSubscription = (subscriptions) => {
  return subscriptions?.find((subscription) => {
    return subscription.status == 'active';
  }) || {};
}